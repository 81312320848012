<template>
    <div class="selection-menu" :style="`top: ${top}px; left: ${left}px;`">
        <div class="section">
            <button class="link" @click.stop="convertTo('link')"><div class="text"><arrow-long />Link</div></button>
            <button v-if="showUnLinkOption" @click.stop="convertTo('unlink')"><div class="text">Unlink</div></button>
        </div>

        <div class="section">
            <button @click.stop="convertTo('bold')" class="bold"><div class="text">B</div></button>
            <button @click.stop="convertTo('italic')" class="italic"><div class="text">i</div></button>
            <button @click.stop="convertTo('underline')" class="underline"><div class="text">U</div></button>
            <button @click.stop="convertTo('strikeThrough')" class="strike-through"><div class="text">S</div></button>
        </div>
    </div>
</template>

<script>
    import ArrowLong from '@/components/Icons/ArrowLong'

    export default {
        components: {
            ArrowLong
        },
        props: {
            position: Object,
            blockId: Number
        },
        watch: {
            position(newValue) { 
                this.top = Math.floor(newValue.y) - 35;
                this.left = Math.floor(newValue.x) - 15;
            }
        },
        data() {
            return { 
                top: 0,
                left: 0,
                showUnLinkOption: false
            }
        },
        mounted() {
            this.top = Math.floor(this.position.y) - 35;
            this.left = Math.floor(this.position.x) - 15;

            const selection = window.getSelection();
            let parentAnchor = false;
            let childAnchor = false;

            // Check if any parent element is an anchor element
            let parentNode = selection.anchorNode.parentNode;
            while (parentNode) {
                if (parentNode.tagName === 'A') {
                    parentAnchor = true;
                    break;
                }
                parentNode = parentNode.parentNode;
            }

            // Check if any child element is an anchor element
            const range = selection.getRangeAt(0);
            const nodeIterator = document.createNodeIterator(range.commonAncestorContainer, NodeFilter.SHOW_ELEMENT);
            let node;
            while ((node = nodeIterator.nextNode())) {
                if (node.tagName === 'A') {
                    childAnchor = true;
                    break;
                }
            }

            if (parentAnchor || childAnchor) {
                this.showUnLinkOption = true;
            } else {
                this.showUnLinkOption = false;
            }
        },
        methods: {
            convertTo(tag) {
                // this.$emit('convertTo', tag)
                if(tag == 'bold') { 
                    this.makeBold();
                } else if(tag == 'italic') {
                    this.makeItalic();
                } else if(tag == 'underline') {
                    this.makeUnderline();
                } else if(tag == 'strikeThrough') {
                    this.makeStrikeThrough();
                } else if( tag == 'link' ) {
                    this.makeLink();
                } else if( tag == 'unlink' ) { 
                    document.execCommand('unlink', false, null);
                }
            },
            makeBold() {
                document.execCommand('bold', false, null);
            },
            makeItalic() {
                document.execCommand('italic', false, null);
            },
            makeUnderline() {
                document.execCommand('underline', false, null);
            },
            makeStrikeThrough() {
                document.execCommand('strikeThrough', false, null);
            },
            makeLink() {
                this.$root.$emit('open_modal', 'create_link_on_project', {
                    selectionRange: document.getSelection().getRangeAt(0), 
                    blockId: this.blockId
                });
            }
        }
    }
</script>